import React from 'react'
import 'scss/skills.scss'
import Layout from 'components/layout'
import NextBtn from 'ui/next-btn'
import SEO from 'components/seo'
import Skill from 'components/skills/skill';

const SkillsPage = () => {
  return (
    <Layout pageTitle="SKILLS">
      <SEO
        title="Skills"
        image="/img/skills-preview.png"
        description="Passionate Web Developer, mostly like to work with javascript framework. Like React.js, Vue.js and Node.js"
      />
      <Skill />
      <NextBtn
        domClassesToHide=".header,.skills__container,.next__btn"
        nextBtnTitle="Contact"
        nextBtnTitleSize="1.3"
        nextPageLink="/contact" />
    </Layout>
  )
}

export default SkillsPage
