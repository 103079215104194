import React, { useEffect, useState } from 'react'
import { showPageWithTransition, parallaxContentWithCursor } from 'Helpers/functions'

const Skill = () => {
  const [skills/*, setskills*/] = useState([
    { id: 1, name: 'React.js', logo: ('/img/all/skills/react-2.png'), alt: 'React.js logo', dataSpeed: -2 },
    { id: 2, name: 'Vue.js', logo: ('/img/all/skills/vue.png'), alt: 'Vue.js logo', dataSpeed: -1 },
    { id: 3, name: 'Typescript', logo: ('/img/all/skills/ts.png'), alt: 'Typescript logo', dataSpeed: -3 },
    { id: 4, name: 'Node.js', logo: ('/img/all/skills/node.png'), alt: 'Node.js logo', dataSpeed: -1 },
    { id: 5, name: 'Javascript', logo: ('/img/all/skills/js.png'), alt: 'javascript logo', dataSpeed: -3 },
    { id: 6, name: 'Apollo', logo: ('/img/all/skills/apollo.png'), alt: 'Apollo logo', dataSpeed: -1 },
    { id: 7, name: 'Redux', logo: ('/img/all/skills/redux.png'), alt: 'redux logo', dataSpeed: -2 },
    { id: 8, name: 'SQL', logo: ('/img/all/skills/sql.png'), alt: 'SQL logo', dataSpeed: -3 },
    { id: 9, name: 'Tailwind CSS', logo: ('/img/all/skills/tailwind-css.svg'), alt: 'tailwind-css logo', dataSpeed: -4 },
  ])

  useEffect(() => {
    showPageWithTransition();
  }, [])
  useEffect(() => {

    if (typeof window !== `undefined`) {
      window.addEventListener('mousemove', (e) => {
        parallaxContentWithCursor(e, '.skills__bgHexItem,.skills__hexItem');
      });
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('mousemove', parallaxContentWithCursor);
      }
    }
  }, []);


  return (
    <div className="skills">
      <div className="skills__container">
        
        <section className="skills__descSection">
          <h2 className="skills__descTitle">
            The latest technologies which passionate me
          </h2>
        </section>

        <section className="skills__section">
          <div className="skills__sectionContainer">

            <div className="skills__hexList">
              {skills.map((skill, idx) =>
                // <div className="skills__hexItemFrame" id={`skill_${skill.id}`} key={`skill_${idx}`}>
                  <div className="skills__hexItem" id={`skill${skill.id}`} key={`skill${idx}`} data-speed={skill.dataSpeed}>
                    <img width="auto" height="auto" src={ skill.logo } className="skills__hexItemImg" id={`skillImg${skill.id}`} alt={skill.alt} data-tooltip={skill.name} />
                  </div>
                // </div>
              )}
            </div>

            <div className="skills__bgHexList">
              <div className="skills__bgHexItem" id="skillBg1" data-speed="-1">
              </div>
              <div className="skills__bgHexItem" id="skillBg2" data-speed="-1">
              </div>
              <div className="skills__bgHexItem" id="skillBg3" data-speed="2">
              </div>
              <div className="skills__bgHexItem" id="skillBg4" data-speed="-1">
              </div>
              <div className="skills__bgHexItem" id="skillBg5" data-speed="1">
              </div>
              <div className="skills__bgHexItem" id="skillBg6" data-speed="3">
              </div>
              <div className="skills__bgHexItem" id="skillBg7" data-speed="2">
              </div>
            </div>

          </div>
          {/* skills__sectionContainer */}
        </section>
        {/* skills__section */}

      </div>
    </div>
  )
}

export default Skill
